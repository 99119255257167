import { useEffect } from 'react'

const KEY = 'previous_locations'

// Запоминает два после
export const useLocationHistory = () => {
  const getPrevLocations = (): string[] => {
    const prevLocation = localStorage.getItem(KEY)
    if (!prevLocation) {
      return []
    }

    return JSON.parse(prevLocation)
  }

  const getPrevLocation = () => {
    const prevLocations = getPrevLocations()
    if (!prevLocations.length || prevLocations[0] === window.location.pathname) {
      return '/'
    }

    return prevLocations[0]
  }

  useEffect(() => {
    const prevLocations = getPrevLocations()
    if (prevLocations[prevLocations.length - 1] !== window.location.pathname) {
      const value = JSON.stringify([...prevLocations, window.location.pathname].slice(-15))
      localStorage.setItem(KEY, value)
    }
  }, [])

  return {
    getPrevLocation,
  }
}
