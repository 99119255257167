import { lazy } from 'react'

import { ModalType } from './_types'
import { AlertModal } from './alert-modal'
import { ConfirmModal } from './confirm-modal'
import { LoginModal } from './login-modal'

// Некоторые могут весить много или содержать недоступные в статике библиотеки
// В этом случае импортируем их лениво
export const loadComponents = () => ({
  [ModalType.ADMIN_ORDER_MODAL]: lazy(() => import('./admin-order-modal/admin-order-modal')),
  [ModalType.SELECT_ALBUM_COVER_MODAL]: lazy(() => import('@modals/select-album-cover-modal/select-album-cover')),
  [ModalType.EDIT_ALBUM_METADATA_MODAL]: lazy(() => import('@modals/edit-album-metadata-modal/edit-album-metadata-modal')),
  [ModalType.LOGIN_MODAL]: LoginModal,
  [ModalType.ALERT_MODAL]: AlertModal,
  [ModalType.CONFIRM_MODAL]: ConfirmModal,
})
