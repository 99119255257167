import { CoverType, OAuthType, User } from '@api/gql/graphql'
import { ModalName, PageName, TopMenuItem } from '@constants/analytic'
import amp from 'amplitude-js'

export enum AmplitudeEvent {
  PageView = 'page.view',
  ModalView = 'modal.view',

  MainClickCreate = 'main.click.create',
  CreateStarted = 'create.started',
  CreateDone = 'create.done',
  LoginClick = 'login.click',
  LoginCompleted = 'login.completed',

  AlbumClick = 'album.click',
  AlbumSelectCoverLayout = 'album.select.cover_layout',
  AlbumDeleteImage = 'album.delete.image',
  AlbumUpdate = 'album.update',
  AlbumUpdateCover = 'album.update.cover',

  OrderClickPurchase = 'order.click.purchase',
  OrderClickReorder = 'order.click.reorder',

  TopMenuSelectMenuItem = 'top_menu.select.menu_item',
  ProfileConfirmAccountDelete = 'profile.confirm.account_delete',

  LogoutClick = 'logout.click',
}

type AmplitudeParams =
    ({ type: AmplitudeEvent.PageView } & { name: PageName }) |
    ({ type: AmplitudeEvent.PageView } & { name: PageName.Order, order_id?: string }) |
    ({ type: AmplitudeEvent.PageView } & {
      name: PageName.OAuthFacebook
      error?: boolean
      error_code?: string | null
      error_description?: string | null
      error_reason?: string | null
      url?: string
    }) |
    ({ type: AmplitudeEvent.PageView } & {
      name: PageName.Create
      auto_create?: boolean
    }) |
    ({ type: AmplitudeEvent.ModalView } & { name: ModalName }) |
    ({ type: AmplitudeEvent.MainClickCreate }) |
    ({ type: AmplitudeEvent.CreateStarted } & { auto_create?: boolean }) |
    ({ type: AmplitudeEvent.CreateDone } & { order_id: string, auto_create?: boolean }) |
    ({ type: AmplitudeEvent.LoginClick } & { oauth_type: OAuthType }) |
    ({ type: AmplitudeEvent.LoginCompleted } & { oauth_type: OAuthType, user_id: string }) |
    ({ type: AmplitudeEvent.AlbumClick } & { album_id: string }) |
    ({ type: AmplitudeEvent.AlbumSelectCoverLayout } & { cover_type: CoverType }) |
    ({ type: AmplitudeEvent.AlbumDeleteImage } & { image_id: string }) |
    ({ type: AmplitudeEvent.AlbumUpdate } & { title: string, creator_name: string }) |
    ({ type: AmplitudeEvent.AlbumUpdateCover } & { album_id: string }) |
    ({ type: AmplitudeEvent.OrderClickPurchase } & { order_id: string }) |
    ({ type: AmplitudeEvent.OrderClickReorder } & { order_id: string }) |
    ({ type: AmplitudeEvent.TopMenuSelectMenuItem } & { name: TopMenuItem }) |
    ({ type: AmplitudeEvent.LogoutClick }) |
    ({ type: AmplitudeEvent.ProfileConfirmAccountDelete })

const init = () => {
  amp.getInstance().init(import.meta.env.VITE_AMPLITUDE_API_KEY)
}

const setUser = (user: User) => {
  amp.getInstance().setUserId(user.id)
  amp.getInstance().setUserProperties({
    email: user.email,
    fb_id: user.fb_id,
    name: user.name,
  })
}

const event = (params: AmplitudeParams) => {
  amp.getInstance().logEvent(params.type, {
    ...params,
    type: undefined,
  })
}

export const amplitude = {
  init,
  event,
  setUser,
}
