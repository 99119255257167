import { FC } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Button } from '@nextui-org/button'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import { useIsMobile } from '@nextui-org/use-is-mobile'

import { ConfirmModalProps, ModalProps } from '../_types'

export const ConfirmModal: FC<ConfirmModalProps & ModalProps> = (props) => {
  const isMobile = useIsMobile()

  const onConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm()
    }

    props.onClose()
  }

  useHotkeys('enter', onConfirm)

  return (
    <Modal placement="center" size="sm" backdrop="blur" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalContent>
        {(onClose) => (
          <form onSubmit={onConfirm} className="flex flex-col justify-center">
            <ModalHeader className="flex flex-col gap-1" />
            <ModalBody>
              <h1 className="text-xl lg:text-2xl font-bold text-center">
                {props.title}
              </h1>

              {!!props.message && (
              <div className="text-gray-500 text-center text-sm lg:text-base">
                {props.message}
              </div>
              )}
            </ModalBody>
            <ModalFooter className="flex flex-row self-center">
              <Button
                size={isMobile ? 'sm' : 'md'}
                color="primary"
                onPress={onConfirm}
              >
                Ok
              </Button>

              {props.onCancel && (
                <Button
                  size={isMobile ? 'sm' : 'md'}
                  variant="bordered"
                  onPress={onClose}
                >
                  Cancel
                </Button>
              )}
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ConfirmModal
