import {
  createContext, ReactNode, useContext, useMemo, useState,
} from 'react'
import { useLocationHistory } from '@helpers/use-location-history'

type State = {
  title?: string
  backPath?: string
}

type HeaderContextProps = {
  setTitle: (title: string) => void
  setBackPath: (backPath: string) => void
}

// В мобильной версии навигация по шапке отличается
// поэтому есть такой контекст который принимает модификации
export const HeaderContext = createContext<HeaderContextProps & State>({
  setTitle: () => {},
  setBackPath: () => {},
})

export const HeaderContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<State>({})

  const value = useMemo((): HeaderContextProps => ({
    ...state,
    setTitle: (v) => setState((s) => ({ ...s, title: v })),
    setBackPath: (v) => setState((s) => ({ ...s, backPath: v })),
  }), [state])

  useLocationHistory()

  return (
    <HeaderContext.Provider value={value}>
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeaderContext = () => {
  return useContext(HeaderContext)
}
